import { useState } from "react";

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    toggleMenu();
  };

  return (
    <nav className="h-[60px] fixed w-full md:relative md:border-2 bg-transparent md:border-black z-40">
      <div className="mx-auto flex justify-between items-center h-full">
        <div className="logo md:border-2 md:border-black h-full flex justify-center items-center w-[80px]">
          <a
            href="/"
            className="hover-inside-border z-[9999] w-full h-full flex justify-center items-center "
            onClick={handleLogoClick}
          >
            <img
              src="/logo.webp"
              alt="DOGE INU - $DINU - Community Owned"
              className="h-[50px] w-[50px]"
            />
          </a>
        </div>
        <div className="hidden md:flex md:justify-between md:flex-1 md:h-full">
          <div className="border-2 border-black h-full w-full flex justify-center items-center">
            <a
              href="/"
              className="hover-inside-border text-[#f56221] main-font border-x-2 border-black w-full flex items-center justify-center font-bold text-2xl h-full text-center"
              onClick={handleLogoClick}
            >
              DOGE INU
            </a>
          </div>
          <div className="border-2 border-black bg-gray-800 h-full w-full flex justify-center items-center">
            <a
              href="https://swap.dogeinu.dog/"
              className="hover-inside-border gap-2  border-x-2 font-semibold border-black w-full flex items-center justify-center h-full text-center"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/dinuswap.svg" className="w-1/2" alt="dinuswap" />
            </a>
          </div>
          <div className="border-2 border-black h-full w-full flex justify-center items-center">
            <a
              href="#section-5"
              className="hover-inside-border border-x-2 font-semibold border-black w-full flex items-center justify-center gap-1 h-full  text-center"
            >
              How to Buy <span className="text-orange-500"> $Dinu</span>
            </a>
          </div>
          <div className="border-2 border-black h-full w-full flex justify-center items-center">
            <a
              href="#section-3"
              className="hover-inside-border gap-2 border-x-2 font-semibold border-black w-full flex items-center justify-center h-full text-center bg-gray-800 text-white"
            >
              ABOUT DOGE INU
            </a>
          </div>
          <div className="border-2 border-black h-full w-full flex justify-center items-center">
            <a
              href="https://t.me/dogeinuCOMMUNITY"
              className="hover-inside-border gap-2  border-x-2 font-semibold border-black w-full flex items-center justify-center h-full text-center"
              target="_blank"
              rel="noreferrer"
            >
              JOIN US
            </a>
          </div>
          <div className="border-2 border-black h-full w-full flex justify-center items-center">
            <a
              href="https://win.dogeinu.dog"
              className="hover-inside-border gap-2  border-x-2 font-semibold border-black w-full flex items-center justify-center h-full bg-gray-800 text-white text-center"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/dinu-win-main.webp" className="w-[30px]" alt="dinuwin" />
              <span className="text-orange-500">DINUWIN </span>
            </a>
          </div>

        </div>
        <div className="md:hidden">
          <button
            className="text-white border-2 relative border-white z-[9999]  bg-orange-500 p-2 mr-3 rounded-full"
            onClick={toggleMenu}
          >
            {isOpen ? (
              <svg
                className="w-6 h-6 transition-transform duration-300 transform"
                fill="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="#fff"
                style={{ transform: "rotate(45deg)" }}
              >
                <path d="M4 12h16M12 4v16"></path>
              </svg>
            ) : (
              <svg
                className="w-6 h-6 transition-transform duration-300 transform"
                fill="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="#fff"
                style={{ transform: "rotate(0deg)" }}
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            )}
          </button>
        </div>
      </div>
      <div
        className={`md:hidden !z-100 bg-orange-500 transition-all duration-500 absolute w-full left-0 top-0`}
        style={{
          height: isOpen ? "100vh" : "0",
          overflow: "hidden",
          marginTop: isOpen ? "0" : "-800px",
        }}
      >
        <div className="flex px-5 flex-col gap-3 mt-[100px]">
          <div className="w-full flex justify-center items-center">
            <a
              href="/"
              className="border-2 text-white border-white py-2 bg-[#0b1f47] w-full flex items-center justify-center font-bold text-3xl h-[80px]"
              onClick={handleLogoClick}
            >
              DOGE INU
            </a>
          </div>
          <div className=" w-full flex justify-center items-center">
            <a
              href="https://swap.dogeinu.dog/"
              className="border-2 text-white border-white font-semibold py-2 gap-1 bg-[#0b1f47] w-full flex items-center justify-center h-[80px]"
              target="_blank"
              rel="noreferrer"
              onClick={toggleMenu}
            >
              <img src="/dinuswap.svg" className="w-1/2" alt="dinuswap" />
            </a>
          </div>
          <div className=" w-full flex justify-center items-center">
            <a
              href="#section-5"
              className="border-2 text-white border-white font-semibold py-2 gap-1 bg-[#0b1f47] w-full flex items-center justify-center h-[80px]"
              onClick={toggleMenu}
            >
              How to Buy <span className="text-orange-500">$DINU</span>
            </a>
          </div>
          <div className="w-full flex justify-center items-center">
            <a
              href="#section-3"
              className="border-2 text-white gap-2  border-white font-semibold py-2 bg-[#0b1f47] w-full flex items-center justify-center h-[80px]"
              onClick={toggleMenu}
            >
              ABOUT DOGE INU
            </a>
          </div>
          <div className="w-full flex justify-center items-center">
            <a
              href="https://t.me/dogeinuCOMMUNITY"
              target="_blank"
              rel="noreferrer"
              className="border-2 text-white gap-2  border-white font-semibold py-2 bg-[#0b1f47] w-full flex items-center justify-center h-[80px]"
              onClick={toggleMenu}
            >
              JOIN US
            </a>
          </div>
          <div className=" w-full flex justify-center items-center">
            <a
              href="https://win.dogeinu.dog/"
              className="border-2 text-white border-white font-semibold py-2 bg-[#0b1f47] w-full flex items-center justify-center h-[80px] gap-2"
              target="_blank"
              rel="noreferrer"
              onClick={toggleMenu}
            >
              <img src="/dinu-win-main.webp" className="w-[30px]" alt="dinuwin" />
              <span className="text-orange-500">DINUWIN </span>
            </a>
          </div>

        </div>
      </div>
    </nav>
  );
}
