import "./App.css";
import { Navbar, SwiperSlider, Footer } from "./components";
import Swal from "sweetalert2";
import { SwiperSlide } from "swiper/react";
import { useSize } from "./utils";

const walletAddress = "0x8a764cf73438de795c98707b07034e577af54825";

export default function App() {
  const { width } = useSize();
  function copyToClipboard() {
    navigator.clipboard.writeText(walletAddress);
    Swal.fire({
      title: "$Dinu Contract Address copied successfully",
      width: 600,
      padding: "1rem",
      color: "#f56221 important",
      backdrop: `
        rgba(0,0,123,0.5)
        center top
        no-repeat
      `,
    });
  }
  return (
    <>
      <Navbar />
      <main>
        <section
          className="hero"
          style={{ backgroundImage: "url(/homeBg.webp)" }}
          id="section-1"
        >
          <div className="hero-container">
            <h1 className="hero-title">
              {width > 500 && <img src="/logo-2.png" alt="DINU" />}
              <p>
                <span>M</span>
                <span>E</span>
                <span>E</span>
                <span>T</span>
              </p>
              {width > 500 && <br />}
              {width < 500 && <img src="/logo-2.png" alt="DINU" />}
              <p className="orange mb-5">
                <span>D</span>
                <span>o</span>
                <span>g</span>
                <span className="mr-8">e</span>
                <span>i</span>
                <span>n</span>
                <span>u</span>
              </p>
            </h1>

            <div className="flex flex-col justify-center mt-10 flex-wrap gap-x-10 gap-y-10 items-center">
              <div className="flex gap-5 flex-wrap justify-center">
                <a
                  className="flex items-center gap-2 text-2xl h-[64px] text-white px-3 rounded-full"
                  href="https://www.mexc.com/exchange/DINU_USDT"
                  target="_blank"
                  rel="noreferrer"
                  style={{ border: "2px solid #ff4800" }}
                >
                  BUY ON{" "}
                  <img src="/mexc-logo.png" alt="MEXC Global" width={200} />
                </a>
                <a
                  className="flex items-center gap-2 h-[64px] text-2xl text-white px-3 rounded-full"
                  href="https://swap.dogeinu.dog/swap/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ border: "2px solid #ff4800" }}
                >
                  BUY ON <img src="/dinuswap.svg" alt="dinuswap" width={200} />
                </a>
              </div>
              <div className="flex justify-center md:w-fit w-full gap-8 md:gap-5 pb-5 px-5 flex-wrap">
                <button
                  className="hero-btn"
                  onClick={() =>
                    window.open(
                      "https://www.dextools.io/app/en/dogechain/pair-explorer/0x17ee6a045d514818418372b2bb8e9997e71eec8f?t=1711726047387",
                      "_blank"
                    )
                  }
                >
                  <img src="/dextools.svg" alt="dextools dogeinu" />
                </button>

                <button
                  className="hero-btn"
                  onClick={() =>
                    window.open(
                      "https://dexscreener.com/dogechain/0x17ee6a045d514818418372b2bb8e9997e71eec8f ",
                      "_blank"
                    )
                  }
                >
                  <img src="/btn-3.png" alt="dexscreener dogeinu" />
                </button>

                <button
                  className="hero-btn"
                  onClick={() =>
                    window.open(
                      "https://www.coingecko.com/en/coins/doge-inu ",
                      "_blank"
                    )
                  }
                >
                  <img src="/btn-4.webp" alt="coingecko dogeinu" />
                </button>

                <button
                  className="hero-btn"
                  onClick={() =>
                    window.open(
                      "https://coinmarketcap.com/currencies/doge-inu-dinu/",
                      "_blank"
                    )
                  }
                >
                  <img src="/coinmarketcap.png" alt="coinmarketcap dogeinu" />
                </button>

                <button
                  className="hero-btn-2"
                  onClick={() =>
                    window.open("https://t.me/dogeinuCOMMUNITY", "_blank")
                  }
                >
                  Join Us
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="section-3  py-20" id="section-3">
          <div className="flex">
            {width > 1150 && (
              <div className="flex justify-center items-center">
                <img src="/doge-left.webp" alt="DogeInu" className="fly-img" />
              </div>
            )}
            <div className="wrapper mx-auto pt-10">
              <div className="title-wrapper mb-10">
                <h3 className="title text-center">
                  <p>Doge inu</p>
                </h3>
                <img src="/star.png" alt="Abhishek Bhardwaj" />
              </div>
              <h3 className="sub-title text-center">
                <p>On DogeChain</p>
              </h3>
              <div className="content mt-[80px] px-3 md:px-0">
                <div className="flex gap-2 gap-y-[60px] flex-wrap">
                  <p>
                    Launched on August 15, 2022, DINU is a community driven meme
                    token on Dogechain! DINU was made to unleash the creativity
                    and full potential of the DOGE community.{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      href="https://dogechain.dog"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LEARN ABOUT DOGECHAIN!
                    </a>
                  </p>
                  <div className="content-item img-wrapper mx-auto md:mx-0">
                    <p className="!max-w-[150px] text-center">Such Amaze</p>
                    {/* <img src="/star.png" alt="Abhishek Bhardwaj" /> */}
                  </div>
                </div>

                <div className="flex gap-2 gap-y-[60px] md:flex-row flex-col-reverse flex-wrap mt-[80px]">
                  <div className="content-item img-wrapper mx-auto md:mx-0">
                    <p className="!max-w-[150px] text-center">Much Wow</p>
                    {/* <img src="/star.png" alt="Abhishek Bhardwaj" /> */}
                  </div>
                  <p>
                    DOGE Inu is the cute orange ambassador who is helping
                    Dogechain in their mission to onboard millions to crypto.
                    Crazy, right? We are also the first Inu token on Dogechain!
                  </p>
                </div>

                <div className="flex gap-2 gap-y-[60px]  flex-wrap mt-[80px]">
                  <p>
                    Over half of our supply was burned at launch and the
                    liquidity is also burned forever so there is no chance of
                    rug pull. We'll make Dogechain great again.
                  </p>
                  <div className="content-item img-wrapper mx-auto md:mx-0">
                    <p className="!max-w-[150px] text-center">Woof woof</p>
                    {/* <img src="/star.png" alt="Abhishek Bhardwaj" /> */}
                  </div>
                </div>
              </div>
            </div>
            {width > 1150 && (
              <div className="flex justify-center items-center">
                <img src="/doge-right.webp" alt="DogeInu" className="fly-img" />
              </div>
            )}
          </div>
        </section>

        <section className="section-2">
          <div className="wrapper mx-auto">
            <div className="left flex justify-center sm:justify-end border-r-2 border-black items-center flex-wrap">
              <h3 className="title">
                <p>
                  <span>G</span>
                  <span>E</span>
                  <span>T</span>
                </p>
                <br />
                <p>
                  <span>$</span>
                  <span>D</span>
                  <span>i</span>
                  <span>n</span>
                  <span>u</span>
                </p>
              </h3>
              <img src="/get-dinu.webp" alt="DinuInu" />
            </div>

            <div className="right">
              <a href="#section-5" className="p-5 block link bg-blue-500">
                <h3>
                  <span>How to get</span>
                </h3>
                <p>$DINU is on Dogechain. View our tutorial here.</p>
              </a>
              <a
                href="https://swap.dogeinu.dog/"
                className="p-5 block link bg-orange-500 border-t-2 border-black"
                target="_black"
                rel="noreferer"
              >
                <h3 className="flex gap-1">
                  <span>DINUSWAP</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-current rotate-[135deg]"
                  >
                    <path d="M20 11H6.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L6.83 13H20v-2z" />
                  </svg>
                </h3>
              </a>
              <a
                href="https://www.mexc.com/exchange/DINU_USDT"
                className="p-5 block link bg-pink-500 border-t-2 border-black"
                target="_black"
                rel="noreferer"
              >
                <h3 className="flex gap-1">
                  <span>MEXC</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-current rotate-[135deg]"
                  >
                    <path d="M20 11H6.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L6.83 13H20v-2z" />
                  </svg>
                </h3>
              </a>
            </div>
          </div>
        </section>

        <section className="section-4 py-20">
          <div className="main-container mx-auto px-4 md:px-0">
            <div className="flex flex-col justify-center items-center gap-5">
              <div className="address-box rounded-sm">
                <h6 className="sub-title">
                  <p>$Dinu Contract Address</p>
                </h6>
                <div className="address flex-wrap flex gap-2 mt-1">
                  <p>{walletAddress}</p>
                  <button
                    className="copy-btn rounded-sm bg-gray-800 p-1"
                    onClick={copyToClipboard}
                  >
                    <span className="svgIcon">
                      <svg
                        fill="white"
                        viewBox="0 0 384 512"
                        height="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="boxes flex justify-center md:justify-between gap-20 relative">
              <div className="box">
                <h6 className="sub-title mb-3 text-center">
                  <p>0% Tax</p>
                </h6>
                <p className="text-center">
                  We don't do taxes! Buy away, and relax. We go to da moon with
                  0% tax.
                </p>
              </div>
              <div className="img-box box w-full flex justify-center">
                <img src="/center-doge.webp" alt="DINU" />
              </div>
              {/* <div className="box">
                <h6 className="sub-title mb-3">
                  <p>50% Supply Burnt</p>
                </h6>
                <p>50% of the total supply has been burnt to a dead wallet</p>
              </div> */}
              <div className="box">
                <h6 className="sub-title mb-3">
                  <p>Contract Renounced</p>
                </h6>
                <p className="text-center">
                  The contract has been renounced and is now owned by the
                  community
                </p>
              </div>
              {/* <div className="img-box w-full flex justify-center">
                <img src="/logo-2.png" alt="DINU" />
              </div> */}
            </div>
            <div className="boxes bottom-box flex justify-center items-center">
              <div className="box">
                <h6 className="sub-title mb-3">
                  <p>50% Supply Burnt</p>
                </h6>
                <p className="text-center">
                  50% of the total supply has been burnt to a dead wallet
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-5 py-20" id="section-5">
          <div className="main-container mx-auto px-4 md:px-0">
            <h3 className="title text-center mb-5">
              <p>
                HOW TO BUY <span className="text-orange-500">$DINU</span>
              </p>
            </h3>
            <SwiperSlider effect="coverflow">
              <SwiperSlide>
                <div className="book-card book-card-1  p-8">
                  <h6 className="sub-title">
                    <p>
                      How to get <br />
                      <span className="text-orange-500">$Dinu</span>
                    </p>
                  </h6>
                  <p className="sub-title-2 mt-2">On Desktop</p>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="book-card book-card-2  p-8">
                  <h6 className="sub-title-2">INTRODUCTION</h6>
                  <div className="paragraphs flex flex-col gap-2 mt-5">
                    <p>
                      DINU is on Dogechain, the L2 blockchain for DOGE coin.
                    </p>
                    <p>
                      You’ll need to add Dogechain Network to your wallet and
                      bridge DOGE tokens in order to come over!
                    </p>
                    <p>
                      It’s very easy and we’ll guide you through it in a few
                      simple steps!
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="book-card book-card-3 p-8">
                  <h6 className="sub-title-2">
                    STEP <span className="text-orange-500">1</span>
                  </h6>
                  <div className="paragraphs flex flex-col gap-2 mt-5">
                    <p>
                      Go to ‘Settings’ in your Metamask wallet and find the
                      networks section.
                    </p>
                    <p>
                      Add a network manually and enter the following info for
                      Dogechain:
                    </p>
                    <p>Network Name: Dogechain Mainnet</p>
                    <p>
                      RPC Endpoint:{" "}
                      <a
                        href="https://rpc.dogechain.dog/"
                        target="_blank"
                        rel="noreferrer"
                        className="book-card-link"
                      >
                        https://rpc.dogechain.dog/
                      </a>
                    </p>
                    <p>Chain ID: 2000</p>
                    <p>Currency Symbol: wDOGE</p>
                    <p>
                      Block Explorer:{" "}
                      <a
                        href="https://explorer.dogechain.dog/"
                        target="_blank"
                        rel="noreferrer"
                        className="book-card-link"
                      >
                        https://explorer.dogechain.dog/
                      </a>
                    </p>
                    <p>
                      Once saved, you should be able to connect to Dogechain by
                      selecting it from the network selection menu.
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="book-card book-card-3 p-8">
                  <h6 className="sub-title-2">
                    STEP <span className="text-orange-500">2</span>
                  </h6>
                  <div className="paragraphs flex flex-col gap-2 mt-5">
                    <p>
                      Next, we need to fund our DogeChain wallet with wrapped
                      DOGE tokens.
                    </p>
                    <p>
                      For this, you’ll need to already own some Dogecoin (DOGE)
                      tokens. If you don’t, you’ll need to get some using a
                      crypto exchange such as Binance.
                    </p>
                    <p>
                      - Open the official DogeChain bridge, at:{" "}
                      <a
                        href="https://dive.dogechain.dog/bridge"
                        target="_blank"
                        rel="noreferrer"
                        className="book-card-link"
                      >
                        https://dive.dogechain.dog/bridge
                      </a>
                    </p>
                    <p>
                      - Connect your wallet to the app. Make sure that you have
                      selected the DogeChain network from within your MetaMask
                      extension or app. Sign the transaction that pops up.
                    </p>
                    <p>
                      - Return to the bridge window and click the green
                      “Confirm” button.
                    </p>
                    <p>
                      - A Dogecoin address will appear, corresponding to your
                      account. Send your desired amount of DOGE to the address
                      shown on your screen.
                    </p>
                    <p>
                      - Once complete, your WDOGE tokens will show in your
                      MetaMask wallet.
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="book-card book-card-3  p-8">
                  <h6 className="sub-title-2">
                    STEP <span className="text-orange-500">3</span>
                  </h6>
                  <div className="paragraphs flex flex-col gap-2 mt-5">
                    <p>
                      Now that your DogeChain wallet is funded and ready to go,
                      its time to buy your $DINU.
                    </p>
                    <p>
                      - Visit the official DinuSwap exchange at{" "}
                      <a
                        href="https://swap.dogeinu.dog/"
                        target="_blank"
                        rel="noreferrer"
                        className="book-card-link"
                      >
                        https://swap.dogeinu.dog/
                      </a>
                    </p>
                    <p>
                      - Enter the Contract Address (CA) for Doge Inu{" "}
                      <span style={{ wordBreak: "break-all" }}>
                        0x8a764cF73438dE795c98707B07034e577Af54825
                      </span>
                    </p>
                    <p>
                      - Swap your desired amount of $WDOGE for $DINU — make sure
                      you leave some WDOGE left over for future gas costs!
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="book-card book-card-3  p-8">
                  <h6 className="sub-title">
                    <p>
                      <span className="text-orange-500">Done</span>
                    </p>
                    <p className="mt-5">You made it DOGE!</p>
                  </h6>
                </div>
              </SwiperSlide>
            </SwiperSlider>
          </div>
        </section>

        {/* <section
          className="section-6  dinu-win py-20 px-4 md:px-0"
          id="dinu-win"
        >
          <div className="mx-auto wrapper">
            <h3 className="title text-center mb-5">
              <p>
                WIN 1T <span className="text-orange-500">DINU</span>
              </p>
            </h3>
            <div className="main-box py-10 px-5 rounded-md mt-10">
              <div className="flex gap-5 md:gap-10 flex-wrap md:flex-nowrap left">
                <div className="md:w-2/5 w-full flex items-center justify-center">
                  <div className="flex flex-col gap-5">
                    <img
                      src="/dinu-win-main.webp"
                      alt="Win 1B Dinu"
                      className="max-w-full img-fly-up-down"
                    />
                    <img
                      src="/dinu-win-main-2.webp"
                      alt="Win 1B Dinu"
                      className="max-w-full img-fly-up-down"
                    />
                  </div>
                </div>
                <div className="w-full right">
                  <h6 className="sub-heading text-orange-500 mb-3">
                    DINU TRILLIONAIRE COMPETTION
                  </h6>
                  <p className="paragraph mb-2">
                    How would YOU like to WIN 1 TRILLION DINU tokens? YES,
                    that’s right, ONE TRILLION DINU tokens are going to be given
                    away to one lucky winner! Read more below!
                  </p>
                  <p className="paragraph mb-2">
                    DINU, the community owned token on DOGECHAIN is pleased to
                    bring you this exciting competition. A competition designed
                    and created by the community for the community. We are
                    extremely grateful to those community members who made this
                    possible. Thank you and good luck to you all!
                  </p>
                  <p className="paragraph mb-2">
                    To enter into the draw to win:
                  </p>

                  <ul className="points pl-5 mb-3">
                    <li className="mb-2">
                      You need to buy (and hold) at least $50 worth of DINU on
                      either{" "}
                      <a
                        href="https://swap.dogeinu.dog/swap/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        DINUswap
                      </a>{" "}
                      or{" "}
                      <a
                        href="https://www.mexc.com/exchange/DINU_USDT"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        MEXC
                      </a>{" "}
                      during the competition period.
                    </li>

                    <li className="mb-2">
                      For every $50 you spend you get 1 ticket, to a maximum of
                      100 tickets. You can enter multiple times throughout the
                      competition period to increase your chances of winning.
                    </li>

                    <li className="mb-2">
                      You will need to follow DOGE INU on Twitter{" "}
                      <a
                        href="https://twitter.com/dogeinudogeinu"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        @DOGEINUDOGEINU
                      </a>
                    </li>

                    <li className="mb-2">
                      You need to retweet the DINU competition{" "}
                      <a
                        href="https://twitter.com/DOGEINUDOGEINU/status/1785641692861026764"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        tweet
                      </a>{" "}
                      from your account.
                    </li>

                    <li className="mb-2">
                      You will need to make a post on Twitter that you have
                      entered the DINU TRILLIONAIRE competition, tagging 3
                      friends in the post as well.
                    </li>

                    <li className="mb-2">
                      If you purchase a ticket on{" "}
                      <a
                        href="https://win.dogeinu.dog/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        DINUwin
                      </a>{" "}
                      you will get a 2x multiplier (once off).
                    </li>

                    <li>
                      YOU MUST COMPLETE THIS{" "}
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdzM4ZPMfoCoO8LeMrSzy-ae7pp8GBeXstzCfyPUJlyUY86Ug/viewform"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        GOOGLE FORM
                      </a>{" "}
                      TO CONFIRM YOUR ENTRY.
                    </li>
                  </ul>

                  <div className="disclamer">
                    <p className="mb-2">Competition rules:</p>
                    <ul className="points pl-5">
                      <li className="mb-2">
                        The total prize is 1 TRILLION DINU tokens to be awarded
                        to ONE winner.
                      </li>
                      <li className="mb-2">
                        The competition will run from 5/01/2024 10pm UTC to
                        6/01/2024 10pm UTC.
                      </li>
                      <li className="mb-2">
                        All rules MUST be followed for your entry to be eligible
                        to enter. No exemptions.
                      </li>
                      <li className="mb-2">
                        Any entries that are deemed malicious will be excluded
                        from the competition.
                      </li>
                      <li className="mb-2">
                        The winner will be announced and notified within 1 WEEK
                        of the competition closing.
                      </li>
                      <li className="mb-2">
                        Winner will receive the tokens over 5 months, in equal
                        instalments. This is to protect the community from bad
                        actors.
                      </li>
                      <li className="mb-2">
                        Proof of Competition Prize of 1T DINU is{" "}
                        <a
                          href="https://explorer.dogechain.dog/address/0x0792b5e82Cc53338b056f298218BEe7423021D5E"
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          held in this address
                        </a>, ready for distribution.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Footer />
      </main>
    </>
  );
}
