import { FaTelegram, FaTwitter, FaMedium } from "react-icons/fa";
export function Footer() {
    return (
        <footer className="text-gray-400 bg-gray-900 body-font">
            <div className="container max-w-[1280px] px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                    <a
                        href="#section-1"
                        className="flex mb-2 title-font font-medium items-center md:justify-start justify-center text-white"
                    >
                        <img
                            src="/logo.webp"
                            className="w-10 h-10 rounded-full"
                            alt="DOGE INU - $DINU - Community Owned | abhibhardwaj1350"
                        />
                        <span className="ml-3 text-xl">Doge Inu</span>
                    </a>
                    <a href="mailto:team@dogeinu.dog" target="_blank" rel="noreferrer">
                        team@dogeinu.dog
                    </a>
                </div>
                <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                    <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                        <nav className="list-none mb-10">
                            <li className="mb-2 text-gray-400 hover:text-white hover:scale-110 transition-transform duration-300 ease-in-out">
                                <a
                                    href="https://dinu.chewyswap.dog/swap"
                                    target="blank"
                                    rel="noreferer"
                                >
                                    Buy $Dinu
                                </a>
                            </li>
                            <li className="mb-2 mb-2 text-gray-400 hover:text-white hover:scale-110 transition-transform duration-300 ease-in-out">
                                <a
                                    href="https://www.dextools.io/app/en/dogechain/pair-explorer/0x17ee6a045d514818418372b2bb8e9997e71eec8f?t=1711726047387"
                                    target="blank"
                                    rel="noreferer"
                                >
                                    Chart
                                </a>
                            </li>
                        </nav>
                    </div>
                    <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                        <nav className="list-none mb-10">
                            <li className="mb-2 text-gray-400 hover:text-white hover:scale-125 transition-transform duration-300 ease-in-out">
                                <a
                                    href="https://www.coingecko.com/en/coins/doge-inu"
                                    target="blank"
                                    rel="noreferer"
                                >
                                    coingecko
                                </a>
                            </li>
                            <li className="mb-2 text-gray-400 hover:text-white hover:scale-125 transition-transform duration-300 ease-in-out">
                                <a
                                    href="https://coinmarketcap.com/currencies/doge-inu-dinu/"
                                    target="blank"
                                    rel="noreferer"
                                >
                                    Coinmarketcap
                                </a>
                            </li>
                        </nav>
                    </div>
                    <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                        <span className="inline-flex gap-5 sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                            <a
                                href="https://t.me/dogeinuCOMMUNITY"
                                rel="noreferrer"
                                target="_blank"
                                className="text-gray-400 hover:text-white hover:scale-125 transition-transform duration-300 ease-in-out"
                            >
                                <FaTelegram size={40} className="" />
                            </a>
                            <a
                                href="https://twitter.com/dogeinudogeinu"
                                rel="noreferrer"
                                target="_blank"
                                className="text-gray-400 hover:text-white hover:scale-125 transition-transform duration-300 ease-in-out"
                            >
                                <FaTwitter size={40} />
                            </a>
                            <a
                                href="https://medium.com/@DogeINU_DC"
                                rel="noreferrer"
                                target="_blank"
                                className="text-gray-400 hover:text-white hover:scale-125 transition-transform duration-300 ease-in-out"
                            >
                                <FaMedium size={40} />
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            <div className="bg-gray-800 bg-opacity-75">
                <div className="container max-w-[1280px]  mx-auto py-4 px-5 flex flex-col items-center justify-center">
                    <p className="text-gray-400 text-sm text-center sm:text-left mb-3">
                        © {new Date().getFullYear()} Doge Inu
                    </p>
                    <p className="disclamer text-center">
                        ⚠️ $DINU is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. It is a community owned cyrptocurrency and the coin is completely useless and for entertainment purposes only. 🐶
                    </p>
                </div>
            </div>
        </footer>
    );
}
